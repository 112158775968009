import { withStyles } from "@material-ui/styles";
import React from "react";
import styles from "./styles/FooterStyles";

function Footer(props) {
  const { paletteName, emoji, classes } = props;
  return (
    <footer className={classes.paletteFooter}>
      {paletteName} <span>&nbsp;&nbsp;&nbsp;</span>
      <span className={classes.emoji}>{emoji}</span>
    </footer>
  );
}

export default withStyles(styles)(Footer);
